import React from "react";
import Alert from "./Alert";
import { useLocation } from "react-router-dom";

const Message = (props: { search: string }) => {
  const params = new URLSearchParams(props.search);
  let message: string | number | null = params.get("message");
  if (message) {
    message = parseInt(message, 10);
    if (!isNaN(message)) {
      const { messages } = require("../utils/landingMessages");
      if (message in messages) {
        return (
          <Alert severity={messages[message].severity}>
            {messages[message].code}
          </Alert>
        );
      }
    }
  }
  return null;
};

const Layout = (props: { children: React.ReactNode }) => {
  const { search } = useLocation();
  return (
    <div style={{ display: "grid", placeItems: "center", height: "100%" }}>
      <div style={{ width: "400px" }} className="vGroup">
        <Message search={search} />
        <div>{props.children}</div>
      </div>
    </div>
  );
};

export default Layout;
