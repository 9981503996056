import React from "react";

const CircularProgress = (props: { style?: React.CSSProperties }) => {
  return (
    <svg
      className="circular-progress"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <path
        d="
        M 100, 100
        m -75, 0
        a 75,75 0 1,0 150,0
        a 75,75 0 1,0 -150,0
    "
      />
    </svg>
  );
};

export default CircularProgress;
