import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from ".";
import client from "../utils/client";
import { LIST, TODOS, GOALS } from "../utils/queries";

type List = any;
type Goal = any;

type ListState = {
  list: List | null;
  todos: Goal[];
  todosLoading: boolean;
  loading: boolean;
  goals: Goal[]; // used on outline page
};

const initialState: ListState = {
  list: null,
  loading: true,
  todos: [],
  todosLoading: false,
  goals: [],
};

const list = createSlice({
  name: "list",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<List>) {
      state.list = action.payload;
      state.loading = false;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setTodos(state, action: PayloadAction<Goal[]>) {
      state.todos = action.payload;
      state.todosLoading = false;
    },
    setTodosLoading(state, action: PayloadAction<boolean>) {
      state.todosLoading = action.payload;
    },
    setGoals(state, action: PayloadAction<Goal[]>) {
      state.goals = action.payload;
    },
    reset(state) {
      state.list = initialState.list;
      state.loading = initialState.loading;
      state.todos = initialState.todos;
      state.goals = initialState.goals;
    },
  },
});

export const {
  setList,
  setLoading,
  setTodos,
  setTodosLoading,
  setGoals,
  reset,
} = list.actions;

export default list.reducer;

export const fetchList = (
  id: string,
  callback?: () => void
): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  const result = await client.query({ query: LIST, variables: { id } });
  dispatch(setList(result.data.list));
  if (callback) {
    callback();
  }
};

export const fetchTodos = (callback?: () => void): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(setTodosLoading(true));
  const { list } = getState();
  const id = list.list.id;
  const result = await client.query({
    query: TODOS,
    variables: { id },
    fetchPolicy: "no-cache",
  });
  dispatch(setList(result.data.list));
  dispatch(setTodos(result.data.list.todos));
  if (callback) {
    callback();
  }
};

export const fetchGoals = (): AppThunk => async (dispatch, getState) => {
  const { list } = getState();
  const id = list.list.id;
  const result = await client.query({
    query: GOALS,
    variables: { id },
    fetchPolicy: "no-cache",
  });
  dispatch(setGoals(result.data.list.goals));
};
