import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ElementDefinition } from "cytoscape";
import { AppThunk } from ".";
import client from "../utils/client";
import { MAP_ELEMENTS } from "../utils/queries";
import { generateGraphElements } from "../utils/map";

type MapState = {
  elements: ElementDefinition[];
};

const initialState: MapState = {
  elements: [],
};

const map = createSlice({
  name: "map",
  initialState,
  reducers: {
    setElements(state, action: PayloadAction<ElementDefinition[]>) {
      state.elements = action.payload;
    },
    resetElements(state) {
      state.elements = [];
    },
  },
});

export const { setElements, resetElements } = map.actions;

export default map.reducer;

export const fetchMapElements = (id: string): AppThunk => async (dispatch) => {
  const result = await client.query({
    query: MAP_ELEMENTS,
    variables: { id },
    fetchPolicy: "no-cache",
  });
  const { goals, dependencies } = result.data.list;
  const elements = generateGraphElements(goals, dependencies, id);
  dispatch(setElements(elements));
};
