import React, { useEffect, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "nprogress/nprogress.css";
import { authenticate } from "../store/user";
import Loading from "./Loading";
import Layout from "./Layout";
import AuthLayout from "./AuthLayout";
import { RootState } from "../store";

const Lists = React.lazy(() => import("./Lists"));
const Profile = React.lazy(() => import("./Profile"));
const Collaborate = React.lazy(() => import("./Collaborate"));
const Navigator = React.lazy(() => import("./Navigator"));
const ResetPassword = React.lazy(() => import("./ResetPassword"));
const SignIn = React.lazy(() => import("./SignIn"));
const SignUp = React.lazy(() => import("./SignUp"));
const ForgotPassword = React.lazy(() => import("./ForgotPassword"));

const AppRouter: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, loggedIn } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(authenticate());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  if (loggedIn === false) {
    return (
      <Layout>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/" component={SignIn} />
          </Switch>
        </Suspense>
      </Layout>
    );
  }

  return (
    <AuthLayout>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/lists" component={Lists} />
          <Route path="/list/:listId" component={Navigator} />
          <Route path="/collaborate" component={Collaborate} />
          <Route path="/profile" component={Profile} />
          <Redirect to="/lists" />
        </Switch>
      </Suspense>
    </AuthLayout>
  );
};

export default AppRouter;
