import { gql } from "@apollo/client";

export const INVITE_COLLABORATOR = gql`
  mutation INVITE_COLLABORATOR(
    $senderId: String!
    $receiverEmail: String!
    $listId: ID
  ) {
    inviteCollaborator(
      senderId: $senderId
      receiverEmail: $receiverEmail
      listId: $listId
    )
  }
`;

export const ADD_COLLABORATOR_TO_LIST = gql`
  mutation ADD_COLLABORATOR_TO_LIST($listId: ID!, $collaboratorIds: [ID!]!) {
    addCollaboratorToList(listId: $listId, collaboratorIds: $collaboratorIds)
  }
`;

export const UPLOAD_AVATAR = gql`
  mutation UPLOAD_AVATAR($file: Upload!) {
    uploadAvatar(file: $file)
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UPDATE_PASSWORD(
    $currentPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    updatePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    )
  }
`;

export const USER_COLLABORATORS = gql`
  query USER_COLLABORATORS {
    me {
      id
      collaborators {
        id
        avatar_url
        name
        email
      }
    }
  }
`;

export const USER_INVITES = gql`
  query USER_INVITES {
    me {
      id
      invites {
        id
        sender {
          name
          avatar_url
        }
        expiry
      }
    }
  }
`;

export const USER_PENDING_INVITES = gql`
  query USER_PENDING_INVITES {
    me {
      id
      pendingInvites {
        id
        expiry
        receiver_email
      }
    }
  }
`;

export const DECLINE_INVITATION = gql`
  mutation DECLINE_INVITATION($invitationId: Int!) {
    declineInvitation(invitationId: $invitationId)
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation ACCEPT_INVITATION($invitationId: Int!) {
    acceptInvitation(invitationId: $invitationId)
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($name: String, $email: String) {
    updateUser(name: $name, email: $email) {
      id
      name
      email
    }
  }
`;

export const SEND_FEEDBACK = gql`
  mutation SEND_FEEDBACK($message: String!) {
    sendFeedback(message: $message)
  }
`;

export const FINISHED_LISTS = gql`
  query FINISHED_LISTS {
    finishedLists {
      id
      finished
      primaryGoal {
        title
      }
    }
  }
`;

export const ADD_NEW_DEPENDENCY = gql`
  mutation ADD_NEW_DEPENDENCY(
    $title: String!
    $listId: ID!
    $dependentGoal: String!
  ) {
    addNewDependency(
      title: $title
      listId: $listId
      dependentGoal: $dependentGoal
    )
  }
`;

export const ADD_EXISTING_DEPENDENCY = gql`
  mutation ADD_EXISTING_DEPENDENCY($goalId: ID!, $depId: ID!, $listId: ID!) {
    addExistingDependency(goalId: $goalId, depId: $depId, listId: $listId)
  }
`;

export const VALID_SIBLING_DEPENDENCIES = gql`
  query VALID_SIBLING_DEPENDENCIES($id: ID!) {
    validSiblingDependencies(id: $id) {
      id
      title
    }
  }
`;

export const REMOVE_DEPENDENCY = gql`
  mutation REMOVE_DEPENDENCY($child: ID!, $parent: ID!) {
    removeDependency(child: $child, parent: $parent)
  }
`;

export const REMOVE_AND_DELETE_DEPENDENCY = gql`
  mutation REMOVE_AND_DELETE_DEPENDENCY($id: ID!) {
    removeAndDeleteDependency(id: $id)
  }
`;

export const RENAME_GOAL = gql`
  mutation RENAME_GOAL($title: String!, $id: ID!) {
    renameGoal(title: $title, id: $id) {
      id
      title
      created
      finished
      dependencies {
        id
        title
        created
      }
    }
  }
`;

export const DELETE_GOAL = gql`
  mutation DELETE_GOAL($id: ID!) {
    deleteGoal(id: $id)
  }
`;

export const MARK_LIST_DONE = gql`
  mutation MARK_LIST_DONE($id: ID!) {
    markListDone(id: $id)
  }
`;

export const DELETE_LIST = gql`
  mutation DELETE_LIST($id: ID!) {
    deleteList(id: $id)
  }
`;

export const CREATE_LIST = gql`
  mutation CREATE_LIST($title: String!) {
    createList(title: $title) {
      id
      created
      finished
      primaryGoal {
        title
      }
    }
  }
`;

export const LISTS = gql`
  query LISTS($archived: Boolean, $finished: Boolean) {
    lists(archived: $archived, finished: $finished) {
      id
      created
      updated
      total_num_goals
      total_finished_goals
      numTodos
      creator
      collaborators {
        avatar_url
        name
      }
      primaryGoal {
        title
      }
    }
  }
`;

export const MAP_ELEMENTS = gql`
  query MAP_ELEMENTS($id: ID!) {
    list(id: $id) {
      goals {
        id
        title
        finished
      }
      dependencies {
        parent_id
        child_id
      }
    }
  }
`;

export const PRIMARY_GOAL = gql`
  query PRIMARY_GOAL($listId: ID!) {
    list(id: $listId) {
      primaryGoal {
        id
        title
      }
    }
  }
`;

export const GOAL_TITLE = gql`
  query GOAL_TITLE($id: ID!) {
    goal(id: $id) {
      title
      finished
    }
  }
`;

export const FIND_GOALS = gql`
  query FIND_GOALS($listId: ID!, $searchTerm: String!) {
    findGoals(listId: $listId, searchTerm: $searchTerm) {
      id
      title
    }
  }
`;

const userBase = gql`
  fragment UserBase on User {
    id
    name
    email
    avatar_url
  }
`;

export const ME = gql`
  ${userBase}
  query ME {
    me {
      ...UserBase
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation REQUEST_PASSWORD_RESET($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD(
    $email: String!
    $resetToken: String!
    $password: String!
  ) {
    resetPassword(email: $email, resetToken: $resetToken, password: $password)
  }
`;

export const SIGN_UP = gql`
  ${userBase}
  mutation SIGN_UP($name: String!, $email: String!, $password: String!) {
    signUp(name: $name, email: $email, password: $password) {
      ...UserBase
    }
  }
`;

export const SIGN_IN = gql`
  ${userBase}
  mutation SIGN_IN($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      ...UserBase
    }
  }
`;

export const MARK_GOAL_DONE = gql`
  mutation MARK_GOAL_DONE($id: ID!) {
    markGoalDone(id: $id)
  }
`;

export const GOAL = gql`
  query GOAL($id: ID!) {
    goal(id: $id) {
      id
      title
      created
      finished
      dependencies {
        id
        title
        created
        depended_on_x_times
        finished
      }
      dependedOnBy {
        id
        title
        created
      }
    }
  }
`;

const listBase = `
id
total_num_goals
creator
collaborators {
  id
  name
  email
  avatar_url
}
primaryGoal {
  id
  title
}
`;

export const LIST = gql`
  query LIST($id: ID!) {
    list(id: $id) {
      ${listBase}
    }
  }
`;

export const TODOS = gql`
  query TODOS($id: ID!) {
    list(id: $id) {
      ${listBase}
      todos {
        id
        title
        created
      }
    }
  }
`;

export const GOALS = gql`
  query GOALS($id: ID!) {
    list(id: $id) {
      ${listBase}
      goals {
        id
        title
      }
    }
  }
`;

export const AUTHENTICATE = gql`
  query AUTHENTICATE {
    authenticate {
      id
      name
      email
      avatar_url
    }
  }
`;

export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut
  }
`;

export const ARCHIVE_LIST = gql`
  mutation ARCHIVE_LIST($id: ID!) {
    archiveList(id: $id)
  }
`;

export const DELETE_SELF = gql`
  mutation DELETE_SELF {
    deleteSelf
  }
`;
