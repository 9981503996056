import React from "react";
import CircularProgress from "./CircularProgress";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { SEND_FEEDBACK } from "../utils/queries";

type FeedbackModalProps = {
  handleClose: any;
};

type FormData = {
  feedbackMessage: string;
};

const FeedbackModal: React.FC<FeedbackModalProps> = ({ handleClose }) => {
  const { register, handleSubmit } = useForm<FormData>();

  const [sendFeedback, { loading }] = useMutation(SEND_FEEDBACK, {
    onCompleted: handleClose,
  });

  const onSubmit = ({ feedbackMessage }: FormData) => {
    if (feedbackMessage) {
      sendFeedback({ variables: { message: feedbackMessage } });
    }
  };

  return (
    <div>
      <form className={"vGroup"} onSubmit={handleSubmit(onSubmit)}>
        <h2>Feedback</h2>
        <p>
          We're grateful for any and all feedback, comments, or suggestions.
        </p>
        <textarea
          style={{
            padding: "1rem",
            fontSize: "1rem",
          }}
          aria-label="feedback"
          {...register("feedbackMessage")}
          placeholder="Enter your feedback here..."
        />
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <button type="submit">Submit</button>
        )}
      </form>
    </div>
  );
};

export default FeedbackModal;
