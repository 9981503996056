import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionsObject } from "notistack";

type Notification = {
  key: number;
  message: string;
  options?: OptionsObject;
  dismissed: boolean;
};

type SnackbarState = {
  notifications: Notification[];
};

const initialState: SnackbarState = {
  notifications: [],
};

const snackbar = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    addNotification(
      state,
      action: PayloadAction<{
        key: number;
        message: string;
        options?: OptionsObject;
      }>
    ) {
      console.log(`Recieved message: ${action.payload.message}`);
      state.notifications.push({
        key: action.payload.key,
        message: action.payload.message,
        dismissed: false,
        // options: action.payload.options,
      });
    },
    closeNotification(state, action: PayloadAction<{ key: number }>) {
      state.notifications = state.notifications.map((n) =>
        n.key === action.payload.key ? { ...n, dismissed: true } : n
      );
    },
  },
});

export const { addNotification, closeNotification } = snackbar.actions;

export default snackbar.reducer;

export const generateNotificationKey = () =>
  new Date().getTime() + Math.random();
