import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/link-error";
import {
  addNotification,
  closeNotification,
  generateNotificationKey,
} from "../store/snackbar";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors?.length || networkError) {
    let message = graphQLErrors?.length
      ? graphQLErrors[0].message
      : networkError?.message || "Unknown Error";
    const store = require("../store");
    const key = generateNotificationKey();
    store.default.dispatch(
      addNotification({
        key,
        message,
        options: {
          variant: "error",
          action: () => (
            <button
              onClick={() => store.default.dispatch(closeNotification({ key }))}
              style={{ color: "white" }}
            >
              Ok
            </button>
          ),
        },
      })
    );
  }
});

const link = ApolloLink.from([
  errorLink,
  new HttpLink({
    uri: process.env.REACT_APP_BACKEND_URL,
    credentials: "include",
  }),
]);

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache,
  link,
  credentials: "include",
});

export default client;
