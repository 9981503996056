import { ElementDefinition } from "cytoscape";

const lineColor = "#ddd";

export function generateGraphElements(
  goals: any[],
  dependencies: any[],
  listId: string
): ElementDefinition[] {
  const nodes = goals.map((goal) => ({
    data: {
      id: goal.id,
      title: goal.title,
      state: goal.finished ? "DONE" : "READY",
      href: `/list/${listId}/goal/${goal.id}`,
    },
  }));

  let edgeIds = 1;

  const edges = dependencies.reduce((acc, dep) => {
    acc = [
      ...acc,
      {
        data: {
          id: (edgeIds++).toString(),
          source: dep.parent_id,
          target: dep.child_id,
          type: "DEPENDENCY",
        },
      },
    ];
    return acc;
  }, []);

  return [...nodes, ...edges];
}

export const style = [
  {
    selector: "node",
    style: {
      label: "data(title)",
      "font-size": "5vw",
      width: "10vw",
      height: "10vw",
      "background-image": "linear-gradient(45deg, blue, transparent)",
      "text-halign": "right" as const,
      "text-valign": "center" as const,
      "text-margin-x": 3,
      "text-wrap": "wrap" as const,
      "text-max-width": "60",
    },
  },
  {
    selector: 'node[state="DONE"]',
    style: {
      "background-color": "rgb(0, 202, 78)",
    },
  },
  {
    selector: "edge",
    style: {
      "curve-style": "straight",
      "target-arrow-shape": "triangle",
      width: "1vw",
      "line-style": "solid",
      "line-color": lineColor,
      "target-arrow-color": lineColor,
      "arrow-scale": "0.75",
      "source-distance-from-node": "5px",
      "target-distance-from-node": "5px",
    },
  },
];
