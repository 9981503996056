import React from "react";
import { MessageSeverity } from "../utils/landingMessages";

function alertStyle(severity: MessageSeverity) {
  let style: React.CSSProperties = {
    fontSize: "small",
    padding: "1rem",
  };
  switch (severity) {
    case "info":
      style.backgroundColor = "lightblue";
      break;
    case "success":
      style.backgroundColor = "var(--accent)";
  }
  return style;
}

const Alert = (props: {
  severity: MessageSeverity;
  children: React.ReactNode;
}) => {
  return <div style={alertStyle(props.severity)}>{props.children}</div>;
};

export default Alert;
