import React from "react";
import CircularProgress from "./CircularProgress";

const Loading = () => {
  return (
    <div
      style={{
        height: "100%",
        display: "grid",
        placeItems: "center center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Loading;
