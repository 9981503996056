import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "../store/user";
import { APP_NAME } from "../utils/constants";

type NavProps = {
  openFeedbackForm: () => void;
};

const Nav: React.FC<NavProps> = ({ openFeedbackForm }) => {
  const dispatch = useDispatch();
  return (
    <div className="nav-container">
      <nav>
        <Link to="/lists">{APP_NAME}</Link>
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            gridGap: "1rem",
            alignItems: "center",
          }}
        >
          <NavLink to="/lists">Lists</NavLink>
          <NavLink to="/collaborate">Collaborate</NavLink>
          <NavLink to="/profile">Profile</NavLink>
          <button onClick={openFeedbackForm}>Send Feedback</button>
          <button
            key="Log Out"
            onClick={() => {
              dispatch(signOut());
            }}
          >
            Log Out
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
