import React from "react";
import { withSnackbar, ProviderContext } from "notistack";

type ErrorBoundaryProps = ProviderContext & { openFeedbackForm: () => void };

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean; error: Error | null }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }
  componentDidCatch(error: Error) {
    this.setState({ hasError: true, error });
    this.props.enqueueSnackbar(error.message, { variant: "error" });
  }
  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "grid",
            gridGap: "1rem",
            height: "100%",
            alignContent: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h1>{`😿`}</h1>
          <h2>Oops! Something went wrong</h2>
          <p>{this.state.error?.toString()}</p>
          <button onClick={this.props.openFeedbackForm}>
            Let us know what went wrong
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withSnackbar(ErrorBoundary);
