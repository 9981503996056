import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from ".";
import client from "../utils/client";
import { AUTHENTICATE, SIGN_OUT } from "../utils/queries";

type TUser = { id: string; name: string; email: string; avatar_url?: string };

type UserState = {
  loggedIn: boolean;
  me: null | TUser;
  loading: boolean;
};

const initialState: UserState = {
  loggedIn: false,
  me: null,
  loading: true,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<null | TUser>) => {
      if (action.payload !== null) {
        state.loggedIn = true;
        state.me = action.payload;
      } else {
        state.loggedIn = false;
        state.me = null;
      }
      state.loading = false;
    },
    logOut: (state) => {
      state.loggedIn = false;
      state.me = null;
    },
    setNewAvatar(state, action: PayloadAction<string>) {
      if (state.me) {
        state.me.avatar_url = action.payload;
      }
    },
  },
});

export const { logIn, logOut, setNewAvatar } = user.actions;

export default user.reducer;

export const authenticate = (): AppThunk => async (dispatch) => {
  const { data } = await client.query({ query: AUTHENTICATE });

  dispatch(logIn(data.authenticate));
};

export const signOut = (): AppThunk => async (dispatch) => {
  const { data } = await client.mutate({ mutation: SIGN_OUT });

  if (data.signOut) {
    // Empty apollo store
    client.cache.reset();

    dispatch(logOut());
  }
};
