import { Action } from "redux";
import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import user from "./user";
import list from "./list";
import goal from "./goal";
import map from "./map";
import snackbar from "./snackbar";

const store = configureStore({
  reducer: {
    user,
    list,
    goal,
    map,
    snackbar,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
