import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from ".";
import client from "../utils/client";
import { GOAL } from "../utils/queries";

type Goal = any;

type GoalState = {
  goal: Goal;
  loading: boolean;
};

const initialState: GoalState = {
  goal: null,
  loading: true,
};

const goal = createSlice({
  name: "goal",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setGoal(state, action: PayloadAction<Goal>) {
      state.goal = action.payload;
      state.loading = false;
    },
    reset(state) {
      state.goal = initialState.goal;
      state.loading = initialState.loading;
    },
  },
});

export const { setLoading, setGoal, reset } = goal.actions;

export default goal.reducer;

export const fetchGoal = (
  id: string,
  callback?: () => void
): AppThunk => async (dispatch) => {
  setLoading(true);
  const result = await client.query({
    query: GOAL,
    variables: { id },
    fetchPolicy: "no-cache",
  });
  dispatch(setGoal(result.data.goal));
  if (callback) {
    callback();
  }
};
