import React from "react";
import Nav from "./Nav";
import FeedbackModal from "./FeedbackModal";
import ErrorBoundary from "./ErrorBoundary";
import { SnackbarProvider } from "notistack";
import Notifier from "./Notifier";
import { useDialogState } from "reakit/Dialog";
import Dialog from "./Dialog";

const AuthLayout: React.FC = ({ children }) => {
  const feedbackDialog = useDialogState();
  return (
    <SnackbarProvider>
      <Notifier />
      <div
        style={{
          display: "grid",
          gridTemplateRows: "auto minmax(0, 1fr)",
          width: "100%",
          height: "100%",
        }}
      >
        <Nav openFeedbackForm={feedbackDialog.show} />
        <div
          style={{
            padding: "0",
          }}
        >
          <ErrorBoundary openFeedbackForm={feedbackDialog.show}>
            <div style={{ height: "100%" }}>{children}</div>
          </ErrorBoundary>
        </div>
      </div>
      <Dialog dialog={feedbackDialog}>
        <FeedbackModal handleClose={feedbackDialog.hide} />
      </Dialog>
    </SnackbarProvider>
  );
};

export default AuthLayout;
