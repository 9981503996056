import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { ApolloProvider } from "@apollo/client";
import client from "./utils/client";
import { Provider } from "react-redux";
import store from "./store";

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router>
          <AppRouter />
        </Router>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
