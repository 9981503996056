export type MessageSeverity = "success" | "info";

export const messages: {
  [key: number]: { severity: MessageSeverity; code: string };
} = {
  10: {
    severity: "success",
    code: `You've successfully updated your password`,
  },
  20: {
    severity: "info",
    code: `Check your e-mail for instructions on resetting your password`,
  },
};
