import React, { ReactNode } from "react";
import { Dialog as ReakitDialog, DialogStateReturn } from "reakit/Dialog";

const Dialog = (props: { dialog: DialogStateReturn; children: ReactNode }) => {
  return (
    <ReakitDialog {...props.dialog} aria-label="Welcome" className="app-dialog">
      {props.children}
    </ReakitDialog>
  );
};

export default Dialog;
